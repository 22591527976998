const routes = [
  //公交列表
  {
    path: "/futureTransport/busStop",
    name: "busStop",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTransport/busStop/index"], resolve),
  },
  //公交详情
  {
    path: "/futureTransport/busStopDetail",
    name: "busStopDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTransport/busStop/busStopDetail"], resolve),
  },
  /* 路线规划 */
  // 路线规划
  {
    path: "/futureTransport/newPlaning",
    name: "newPlaning",
    meta: {
      title: "路线引导",
    },
    component: (resolve) =>
      require(["@/views/futureTransport/routePlan/newPlaning"], resolve),
  },
  // 路线规划首页
  {
    path: "/futureTransport/routePlan",
    name: "routePlan",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureTransport/routePlan/index"], resolve),
  },
  // 路线规划查询
  {
    path: "/futureTransport/routePlanSearch",
    name: "routePlanSearch",
    meta: {
      title: "路线引导",
    },
    component: (resolve) =>
      require(["@/views/futureTransport/routePlan/search"], resolve),
  },
  // 路线规划路线
  {
    path: "/futureTransport/routePlanPlanning",
    name: "routePlanPlanning",
    meta: {
      title: "路线引导",
    },
    component: (resolve) =>
      require(["@/views/futureTransport/routePlan/planning"], resolve),
  },
  /** end */
  // start  路线规划改版
  {
    path: "/futureTransport/pathPlaningOut",
    name: "pathPlaningOut",
    meta: {
      title: "路线引导",
    },
    component: (resolve) =>
      require(["@/views/futureTransport/routePlan/pathPlaningOut"], resolve),
  },
  {
    path: "/futureTransport/pathPlaning",
    name: "pathPlaning",
    meta: {
      title: "路线引导",
    },
    component: (resolve) =>
      require(["@/views/futureTransport/routePlan/pathPlaning"], resolve),
  },
  {
    path: "/futureTransport/newPathPlaning",
    name: "newPathPlaning",
    meta: {
      title: "路线引导",
    },
    component: (resolve) =>
      require(["@/views/futureTransport/routePlan/newPathPlaning"], resolve),
  },
  // 路线导航
  {
    path: "/futureTransport/pathNavigation",
    name: "pathNavigation",
    meta: {
      title: "路线引导",
    },
    component: (resolve) =>
      require(["@/views/futureTransport/routePlan/pathNavigation"], resolve),
  },
  // end  路线规划改版
];

export default routes;
