import axios from "axios";
import store from "../store";
import router from "@/router";
import { Toast } from "vant";
import { getHashParam } from "@/utils/utils.js";
import encrypt from "@/utils/encrypt.js";
const key = "SA83ekL2xXuucunRCVWe5mThijh29i"; // 一串固定密文
import md5 from "js-md5";

//区分生产与研发公共前缀api
const currentOrigin = window.location.origin;
let prefixUrl = "";
let productUrl = [
  "http://yshgjyd.nbhq.gov.cn",
  "https://yshgjyd.nbhq.gov.cn",
  "http://122.247.77.44",
  "https://122.247.77.44",
];
if (process.env.NODE_ENV === "production") {
  if (productUrl.includes(currentOrigin)) {
    prefixUrl = "/jieneng";
  } else {
    prefixUrl = "";
  }
} else {
  prefixUrl = "";
}
axios.defaults.baseURL = prefixUrl;
let token = {}
//根据account是否为空做判断 
if (store.state.account || localStorage.getItem("account")) {
  const account = store.state.account || localStorage.getItem("account");
  const password = encrypt.sha1(encrypt.md5.hexMD5(account + key));
  token = {
    account: account,
    password,
  };
} else {
  const account = "h5"
  const timeStamp = new Date().getTime()
  const password = encrypt.sha1(encrypt.md5.hexMD5("h5" + key));
  const sign = md5(`account=${account}&key=${key}&password=${password}&timeStamp=${timeStamp}`).toUpperCase();
  token = {
    account: "h5",
    password,
    timeStamp,
    sign
  };
}


const header = {
  timeout: 200000,
  headers: {
    // companyCode: 'mh',
    // mobileType,
    // token: sessionStorage.getItem('token'),
    // defroomid: JSON.parse(sessionStorage.getItem('user')) ? JSON.parse(sessionStorage.getItem('user')).defRoomId : '',
    // defcommunityid: sessionStorage.getItem('communityId'),
    // companycode: 'ss',
    ...token,
  },
};
function startLoading() {
  let cancelToastLoading = sessionStorage.getItem("cancelToastLoading");
  if (cancelToastLoading) {
    /* 如果cancelToastLoading存在值，则请求接口时自定义加载逻辑
     * 已使用的该逻辑的模块包括：业主投票 futureTreat/householdVote
     */
    return;
  }
  Toast.loading({
    duration: 0,
    message: "加载中...",
    forbidClick: true,
    loadingType: "spinner",
  });
}
function endLoading() {
  let cancelToastLoading = sessionStorage.getItem("cancelToastLoading");
  if (cancelToastLoading) {
    /* 如果cancelToastLoading存在值，则请求接口时自定义加载逻辑
     * 已使用的该逻辑的模块包括：业主投票 futureTreat/householdVote
     */
    return;
  }
  Toast.clear();
}
const _axios = axios.create(header);

_axios.interceptors.request.use(
  (config) => {
    console.log(config, "config---");
    startLoading();
    // let defOrgId = store.state.defOrgId;
    // config.headers = Object.assign({ defOrgId }, config.headers);
    if (config.isQinxue) {
      delete config.headers.account;
      delete config.headers.password;
      config.headers.Authorization = "Basic c3dvcmQ6c3dvcmRfc2VjcmV0";
    } else {
    }

    if (config.params) {
      for (let key in config.params) {
        if (typeof config.params[key] == "string") {
          if (
            config.params[key].trim().length == 0 ||
            config.params[key] == "undefined" ||
            config.params[key] == 0
          ) {
            config.params[key] = null;
          }
        }
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  async (res) => {
    endLoading();
    if (res.status != 200) {
      return Promise.reject(res);
    } else {
      if (res.data.code != 200 && res.msg == "您还没有报名该活动！") {
        Toast("请求错误！");
        return res.data;
      }
      if (res.data.code != 200 && res.data.code != 0) {
        if (res.data.code == "401") {
          router.push({
            name: "promptPage-limit",
          });
        } else if (res.data.code == "400") {
          router.push({
            name: "promptPage-limit",
          });
        } else if (res.data.code == "500") {
          router.push({
            name: "promptPage-serverError",
          });
        } else if (res.data.code == "404") {
          router.push({
            name: "promptPage-notFound",
          });
        } else {
          Toast(res.data.msg);
        }
      }
    }
    return res.data;
  },
  (error) => {
    endLoading();
    if (error.response.status == "401") {
      router.push({
        name: "promptPage-limit",
      });
    } else if (error.response.status == "400") {
      router.push({
        name: "promptPage-limit",
      });
    } else if (error.response.status == "500") {
      router.push({
        name: "promptPage-serverError",
      });
    } else if (error.response.status == "404") {
      router.push({
        name: "promptPage-notFound",
      });
    } else {
      Toast("服务器走丢了");
    }
    return error;
  }
);

export default _axios;
