import { getHashParam } from "@/utils/utils.js";
import store from "@/store";
import axios from "@/axios";
import router from "../router";
//扩展函数
function containsStr(str, val) {
  return str && val && str.indexOf(val) >= 0;
}
String.prototype.ossResize = function (resizeWidth, resizeHeight) {
  if (!resizeWidth || resizeWidth === 0) {
    resizeWidth = 500;
  }
  if (!resizeHeight) {
    resizeHeight = resizeWidth;
  }

  let path = this;
  if (path.length === 0) {
    return path;
  }

  if (path.indexOf(",") >= 0) {
    path = path.split(",")[0];
  }

  let OSS_RESIZE = "?x-oss-process=image/resize,m_fill,w_";
  if (
    !containsStr(path, "?") &&
    (containsStr(path, "http://") ||
      containsStr(path, "https://") ||
      containsStr(path, "ftp://"))
  ) {
    path += OSS_RESIZE + resizeWidth + ",h_" + resizeHeight;
  }
  console.log(path);
  return path;
};

