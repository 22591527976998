const routes = [
  //  空调
  {
    path: "/airConditioner/airConditionerSwitch",
    name: "airConditionerSwitch",
    meta: {
      title: "智能控制",
    },
    component: (resolve) =>
      require(["@/views/airConditioner/airConditionerSwitch.vue"], resolve),
  },
  //  设备
  {
    path: "/equipment/roomEquipment",
    name: "roomEquipment",
    meta: {
      title: "智能控制",
    },
    component: (resolve) =>
      require(["@/views/airConditioner/roomEquipment.vue"], resolve),
  },
  //  智能设备
  {
    path: "/equipment/newRoomEquipment",
    name: "newRoomEquipment",
    meta: {
      title: "智能控制",
    },
    component: (resolve) =>
      require(["@/views/airConditioner/newRoomEquipment.vue"], resolve),
  },
  //  房号选择
  {
    path: "/equipment/roomSelect",
    name: "roomSelect",
    meta: {
      title: "房号选择",
    },
    component: (resolve) =>
      require(["@/views/airConditioner/roomSelect.vue"], resolve),
  },
  //  灯光
  {
    path: "/equipment/lightSwitch",
    name: "lightSwitch",
    meta: {
      title: "智能控制",
    },
    component: (resolve) =>
      require(["@/views/airConditioner/lightSwitch.vue"], resolve),
  },
];

export default routes;
