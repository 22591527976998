const routes = [
  //  访问权限
  {
    path: "/promptPage/limit",
    name: "promptPage-limit",
    meta: {
      title: "",
    },
    component: (resolve) => require(["@/views/promptPage/limit"], resolve),
  },
  //  网络错误
  {
    path: "/promptPage/netFault",
    name: "promptPage-netFault",
    meta: {
      title: "",
    },
    component: (resolve) => require(["@/views/promptPage/netFault"], resolve),
  },
  //  404
  {
    path: "/promptPage/notFound",
    name: "promptPage-notFound",
    meta: {
      title: "",
    },
    component: (resolve) => require(["@/views/promptPage/notFound"], resolve),
  },
  // 服务器错误
  {
    path: "/promptPage/serverError",
    name: "promptPage-serverError",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/promptPage/serverError"], resolve),
  },
];

export default routes;
