<template>
  <div id="app">
    <v-return
      :title="$route.meta.title"
      v-if="routeList.indexOf($route.path) == -1"
    ></v-return>
    <keep-alive>
      <div class="app-l">
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </div>
      <!-- 需要缓存的视图组件 -->
    </keep-alive>
    <!-- 不需要缓存的视图组件 -->
    <div class="app-r">
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
  </div>
</template>
<script>
import Vconsole from "vconsole";
import { getHashParam } from "@/utils/utils.js";
export default {
  name: "areaAppoint",
  data() {
    return {
      routeList: [
        "/futureTransport/pathPlaningOut",
        "/equipment/newRoomEquipment",
      ],
    };
  },
  created() {
    // let vConsole = new Vconsole();
  },
  mounted() {
    // new Vconsole();
    this.toHandleFontSize();
  },
  methods: {
    toHandleFontSize() {
      if (navigator.userAgent.toLowerCase().indexOf("android") !== -1) {
        // eslint-disable-next-line no-inner-declarations
        function handleFontSize() {
          // eslint-disable-next-line no-undef
          const bridge = window.WeixinJSBridge;
          bridge.invoke("setFontSizeCallback", { fontSize: 2 });
          bridge.on("menu:setfont", function () {
            bridge.invoke("setFontSizeCallback", { fontSize: 2 });
          });
        }
        // eslint-disable-next-line no-undef
        if (
          typeof window.WeixinJSBridge == "object" &&
          typeof window.WeixinJSBridge.invoke == "function"
        ) {
          handleFontSize();
        } else {
          if (document.addEventListener) {
            document.addEventListener(
              "WeixinJSBridgeReady",
              handleFontSize,
              false
            );
          } else if (document.attachEvent) {
            document.attachEvent("WeixinJSBridgeReady", handleFontSize);
            document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
          }
        }
      }
    },
  },
};
</script>
<style lang="less">
#app {
  overflow-y: auto;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  .app-l,
  .app-r {
    position: relative;
    z-index: 998;
  }
}
@import url("./assets/less/reset.less");
@import url("./assets/less/common.less");
</style>
