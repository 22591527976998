<template>
  <div class="v-upload">
    <template v-if="target == 'yshgj'">
      <div class="updata-component">
        <div class="demo-upload-box">
          <div class="demo-upload-image-box">
            <div
              class="demo-upload-image"
              v-for="(item, index) in uploadList"
              :key="index"
            >
              <van-image
                fit="contain"
                :src="item.url"
                @click.stop="handleView(index)"
                class="img"
              />
              <div
                class="demo-upload-box-cover"
                @click.stop="handleRemove(index)"
              >
                <img src="./images/delete.png" alt="" />
              </div>
            </div>
            <div class="demo-upload-btn">
              <img
                class="ivu-icon-camera"
                src="./images/add-photo.png"
                alt=""
              />
              <div class="btn" @click="clickFile"></div>
            </div>
          </div>
        </div>
        <!-- 查看大图 -->
        <div class="big-pic" v-if="bigPicSrc" @click.self="bigPicSrc = ''">
          <img class="" :src="bigPicSrc" />
        </div>
      </div>
    </template>
    <template v-else
      ><van-uploader
        v-model="fileList"
        :multiple="multiple"
        :max-count="maxCount"
        :accept="type"
        :upload-icon="addImg"
        :after-read="afterRead"
        :before-delete="beforeDelete"
        :before-read="beforeRead"
        :disabled="disabled"
        :deletable="deletable"
        :show-upload="showUpload"
    /></template>
  </div>
</template>

<script>
import _ from "lodash";
import Vue from "vue";
import { Uploader } from "vant";
import addImg from "./images/add-photo.png";
Vue.use(Uploader);
//安卓
import { uploadFile } from "@/utils/utils.js";
import { Image as vanImage } from "vant";
import { ImagePreview } from "vant";
import "vant/lib/image/style";
import { base64toFile } from "./codeToFile";
//
export default {
  watch: {
    imgUrl(newValue) {
      this.uploadList = [];
      console.log("变化");
      if (typeof newValue !== "string" && this.fileList.length === 0) {
        newValue.forEach((url) => {
          this.fileList.push({ url: url, isImage: true });
        });
      } else {
        if (this.maxCount == 1) {
          let img = newValue ? [{ url: newValue }] : [];
          this.fileList = img;
          this.uploadList = Object.assign(this.fileList);
        } else {
          this.fileList = [];
          if (newValue) {
            let arr = newValue.split(",");
            arr.forEach((ele) => {
              this.fileList.push({ url: ele });
            });
            this.uploadList = Object.assign(this.fileList);
          }
        }
      }
    },
  },
  //安卓
  components: {
    "van-image": vanImage,
  },
  props: {
    // 图片url队列 与fileList二选一 不可同时存在 同时存在的请客fileLis无效
    imgUrls: {
      type: [Array, String],
    },
    uploadUrl: {
      type: String,
      default: "/gateway/blade-resource/oss/endpoint/put-file",
    },
    imgUrl: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "image/*",
    },
    maxCount: {
      type: Number,
      default: 1,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
    // 是否展示上传区域
    showUpload: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  computed: {
    // "yshgj"
    target() {
      return this.$store.state.target;
    },
  },
  data() {
    return {
      addImg,
      fileList: [],
      uploadList: [],
      status: 2,
      bigPicSrc: "",
    };
  },
  created() {
    let newValue = this.imgUrl;
    console.log("初始化");
    this.uploadList = [];
    if (this.maxCount == 1) {
      let img = newValue ? [{ url: newValue }] : [];
      this.fileList = img;
      this.uploadList = Object.assign(this.fileList);
    } else {
      this.fileList = [];
      if (newValue) {
        let arr = newValue.split(",");
        arr.forEach((ele) => {
          this.fileList.push({ url: ele });
        });
        this.uploadList = Object.assign(this.fileList);
      }
    }
  },
  mounted() {
    if (this.target == "yshgj") {
      window.upfiledata = this.upfiledata;
    }
  },
  methods: {
    //安卓
    upfiledata(base64Data, filename) {
      var u = navigator.userAgent; // 安卓
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // IOS
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isAndroid) {
        if (typeof data != "object") {
          let oData = JSON.parse(base64Data);
          if (oData.base64) {
            this.handleSuccess(oData.base64, oData.filename);
          }
        }
      } else if (isiOS) {
        if (base64Data) {
          this.handleSuccess(base64Data, filename);
        }
      }
    },
    handleSuccess(base64, filename) {
      if (this.status == 3) {
        this.$toast("上一张正在上传中，请等待");
        return;
      }
      var formData = new FormData();
      formData.append("file", base64toFile(base64, filename));
      this.status = 3;
      this.$axios.post(`${this.uploadUrl}`, formData).then((res) => {
        this.status = 1;
        if (res.code == 200) {
          if (res.data.link) {
            this.uploadList.push({ url: res.data.link });
            this.updateFile(this.uploadList);
          }
          this.callbcak();
        } else {
          this.$toast(res.msg);
          this.callbcak();
        }
      });
    },
    clickFile() {
      uploadFile();
    },
    // 删除上传的案例图
    handleRemove(index) {
      this.uploadList.splice(index, 1);
      this.$forceUpdate();
    },
    // 查看大图
    handleView(index) {
      this.bigPicSrc = this.uploadList[index]["url"];
    },
    //以上安卓
    beforeRead() {
      console.log(234, 90);
      if (this.status == 3) {
        this.$toast("上一张正在上传中，请等待");
      } else {
        return true;
      }
    },
    beforeDelete(file, detail, ad) {
      let _this = this;
      console.log(file, detail, "删除", ad);
      let arr = _.cloneDeep(_this.uploadList);
      arr.splice(detail.index, 1);
      _this.uploadList = arr;
      _this.init();
      return true;
    },
    afterRead(file, detail) {
      console.log(file, detail);
      var formData = new FormData();
      if (file.file) {
        formData.append("file", file.file);
      }
      file.status = "uploading";
      file.message = "上传中...";
      this.status = 3;
      this.$axios.post(`${this.uploadUrl}`, formData).then((res) => {
        this.status = 1;
        console.log(res, 998);
        if (res.code == 200) {
          if (res.data.link) {
            this.uploadList.push({ url: res.data.link, index: detail.index });
            this.updateFile(this.uploadList);
            this.init();
          }
          file.status = "done";
          file.message = "上传成功";
          this.callbcak();
        } else {
          file.status = "failed";
          file.message = "上传失败";
          this.$toast(res.msg);
          this.callbcak();
        }
        console.log(this.uploadList, 2399888);
      });
    },
    updateFile(val) {
      if (this.limit == 1) {
        let urlArr = val.map((item) => item.url);
        if (val.length > 0) {
          // @arg imgUrls 图片列表 双向绑定 limit为1时String，>1时Array
          val[0].url && this.$emit("update:activityPicture", val[0].url);
        } else {
          this.$emit("update:activityPicture", "");
        }
      } else {
        let urlArr = [];
        val.forEach((item) => {
          urlArr.push(item.url);
        });
        let urlStr = urlArr.join(",");
        this.$emit("update:activityPicture", urlStr);
      }
    },
    init() {
      let arr = [];
      let toArr = [];
      Object.assign(toArr, this.uploadList);
      toArr.forEach((ele) => {
        arr.push(ele.url);
      });
      let urlLink = arr.join(",");
      this.$emit("update:activityPicture", urlLink);
    },
    callbcak() {
      this.$emit("uploadAfter");
    },
  },
};
</script>

<style lang="less">
.v-upload {
  .van-uploader__preview {
    border: 2px dashed #eee;
  }
  .van-uploader__preview-image {
    width: 200px;
    height: 200px;
    box-sizing: border-box;
  }
  .van-uploader__upload {
    width: 200px;
    height: 200px;
    box-sizing: border-box;
  }
  .van-uploader__upload {
    margin: 0;
  }
}
.updata-component {
  .demo-upload-box {
    display: flex;
    .demo-upload-image-box {
      display: flex;
      flex-wrap: wrap;
    }
    .demo-upload-image {
      width: 200px;
      height: 200px;
      text-align: center;
      line-height: 60px;
      border-radius: 4px;
      position: relative;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      margin: 0 4px 8px 0;
      .img {
        width: 100%;
        height: 100%;
      }
      .demo-upload-box-cover {
        z-index: 99;
        position: absolute;
        top: 0;
        right: 0;
        width: 28px;
        height: 28px;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 0 0 0 24px;
        font-size: 0;
        img {
          width: 20px;
          position: absolute;
          height: 20px;
          top: 2px;
          right: 2px;
        }
      }
    }
    .demo-upload-btn {
      position: relative;
      width: 200px;
      height: 200px;
      background: #f7f8fa;
      border-radius: 4px;
      text-align: center;

      overflow: hidden;
      transition: border-color 0.2s ease;
      .file {
        display: none;
      }
      .btn {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0);
        z-index: 10;
        border: none;
        cursor: pointer;
      }
      .ivu-icon-camera {
        position: absolute;
        width: 50px;
        height: 42.6px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 28px;
        cursor: pointer;
      }
    }
  }
  .big-pic {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9999;
    img {
      position: absolute;
      width: 700px;
      height: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
