<template>
  <div class="home"></div>
</template>
<script>
import { getUserInfo, getOauthCode, getOauthUrl, getUserTypeUrl } from "./api";
import store from "@/store";
import { getUserNO } from "@/utils/utils.js";
export default {
  name: "Home",
  data() {
    return {
      appId: "",
      target: "",
      query: "",
      userNO: "",
      bstop: 1,
      isLocal: false,
      userType: [],
    };
  },
  async created() {},
  async mounted() {
    const query = this.getSearchData(window.location.search);
    this.query = query;
    if (query.target) {
      this.target = query.target;
    }
    if (this.isLocal) {
      this.getLocalUser();
    } else {
      if (this.target == "yshgj") {
        // IOS
        var u = navigator.userAgent;
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        if (isiOS) {
          getUserNO();
          window.setUserNO = this.setUserNO;
        } else {
          this.userNO = getUserNO();
          this.getOauth(this.query);
        }
      } else {
        if (
          Object.prototype.hasOwnProperty.call(query, "jf_token") &&
          Object.prototype.hasOwnProperty.call(query, "redirectUrl")
        ) {
          await this.getInfo(query);
        }
      }
    }
  },
  methods: {
    async getLocalUser() {
      //生产环境用户参数
      // const { account, userId, mobile, userCode } = {
      //   account: "nbsjgswj-qwj",
      //   userId: "1839126130154541057",
      //   mobile: "13454769536",
      //   userCode: "GE_6507f7c9221847adb3e5d8c7cfce0d1e",
      // };
      //研发环境参数
      const { account, userId, mobile, userCode } = {
        account: "dddlcs",
        userId: "1697501921107587074",
        mobile: "13800000001",
        userCode: "c53a5c9d-67f1-4283-bb3d-5f6edcfe6615",
      };
      store.commit("setUserId", userId);
      store.commit("setAccount", account);
      store.commit("setMobile", mobile);
      store.commit("setUserCode", userCode);
      await this.getCode(userId);
      this.$router.replace({
        name: "workbenchHomePage",
      });
    },
    setUserNO(str) {
      this.userNO = str;
      if (this.bstop == 1) {
        this.bstop = 2;
        this.getOauth(this.query);
      }
    },
    async getUserType(mobile) {
      const params = {
        mobile,
      };
      let res = await this.$axios.get(getUserTypeUrl, {
        params,
      });
      if (res.code == 200) {
        res.data = res.data || {};
        this.userType = Object.keys(res.data).map(k => +k);
      }
    },
    async getOauth(query) {
      console.log(this.userNO, "用户");
      this.getAppId(query.redirectUrl);
      const params = {
        is_appid: this.appId,
        person_id: this.userNO,
      };
      let res = await this.$axios.post(getOauthUrl, null, {
        params,
      });
      if (res.code == 200) {
        const { account, userId, mobile } = res.data;
        store.commit("setUserId", userId);
        store.commit("setAccount", account);
        store.commit("setMobile", mobile);
        store.commit("setTarget", this.target);
        await this.getCode(userId);
        await this.getUserType(mobile);
        if (query.redirectUrl == "workbenchHomePage") {
          if (account == "nhzwjsfwdsfjg-lj") {
            this.$router.replace({
              name: query.redirectUrl,
            });
          } else {
            if (this.userType.includes(0)) {
              //默认用户是上报工单页面
              this.$router.replace({
                name: "maintenanceReport",
              });
            } else {
              this.$router.replace({
                name: query.redirectUrl,
              });
            }
          }
        } else {
          this.$router.replace({
            name: query.redirectUrl,
          });
        }
      }
    },
    // 获取所需参数
    getInfo(query) {
      const params = {
        jf_token: query.jf_token,
        is_appid: query.is_appid,
      };
      this.$axios
        .post(getUserInfo, null, {
          params,
        })
        .then(async (res) => {
          if (res.code == 200) {
            const { account, userId, mobile, userCode } = res.data;
            store.commit("setUserId", userId);
            store.commit("setAccount", account);
            store.commit("setMobile", mobile);
            store.commit("setUserCode", userCode);
            await this.getCode(userId);
            await this.getUserType(mobile);
            if (query.redirectUrl == "workbenchHomePage") {
              if (account == "nhzwjsfwdsfjg-lj") {
                this.$router.replace({
                  name: query.redirectUrl,
                });
              } else {
                if (this.userType.includes(0)) {
                  //默认用户是上报工单页面
                  this.$router.replace({
                    name: "maintenanceReport",
                  });
                } else {
                  this.$router.replace({
                    name: query.redirectUrl,
                  });
                }
              }
            } else {
              this.$router.replace({
                name: query.redirectUrl,
              });
            }
          }
        });
    },
    //获取code
    async getCode(userId) {
      const params = {
        userId,
        auth: 1,
      };
      const res = await this.$axios.post(getOauthCode, params);
      if (res.code == 200) {
        const code = res.data;
        store.commit("setCode", code);
      }
    },
    getAppId(redirectUrl) {
      switch (redirectUrl) {
        //路线引导
        case "pathPlaning":
          this.appId = "lxyd";
          break;
        //移动工单
        case "workbenchHomePage":
          this.appId = "gd";
          break;
        //智能控制
        case "roomEquipment":
          this.appId = "znkz";
          break;
        default:
          this.appId = "lxyd";
          break;
      }
    },
    parseURLParams() {
      let queryParams = {};
      let hashParams = {};
      // 获取查询参数字符串
      let queryString = window.location.search.substring(1);
      if (queryString) {
        queryString.split("&").forEach(function (param) {
          var keyValue = param.split("=");
          queryParams[keyValue[0]] = decodeURIComponent(keyValue[1] || "");
        });
      }

      // 获取 hash 参数字符串
      let hashString = window.location.hash.substring(1);
      if (hashString) {
        hashString.split("&").forEach(function (param) {
          let keyValue = param.split("=");
          hashParams[keyValue[0]] = decodeURIComponent(keyValue[1] || "");
        });
      }
      console.log("queryParams----->", queryParams);
      console.log("hashParams>", hashParams);
      return {
        queryParams: queryParams,
        hashParams: hashParams,
      };
    },
    // location.search 获取地址参数 转换为 对象
    getSearchData(search) {
      let obj = {};
      let arr = search.slice(1).split("&");
      arr.forEach((item) => {
        let newArr = item.split("=");
        obj[newArr[0]] = newArr[1];
      });
      return obj;
    },
  },
};
</script>

<style lang="less" scoped></style>
