import Vue from "vue";
import Vuex from "vuex";
import storage from "@/utils/storage";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userId: storage.get("userId") || "",
    account: storage.get("account") || "",
    mobile: storage.get("mobile") || "",
    target: storage.get("target") || "",
    code: storage.get("code") || "",
    userCode: storage.get("userCode") || "",
  },
  mutations: {
    // 入口判断
    setTarget(state, target) {
      state.target = target;
      storage.set("target", target);
    },
    // 用户账号名
    setAccount(state, account) {
      state.account = account;
      storage.set("account", account);
    },
    //用户手机号
    setUserId(state, userId) {
      state.userId = userId;
      storage.set("userId", userId);
    },
    //手机号
    setMobile(state, mobile) {
      state.mobile = mobile;
      storage.set("mobile", mobile);
    },
    //code
    setCode(state, code) {
      state.code = code;
      storage.set("code", code);
    },
    //code
    setUserCode(state, code) {
      state.userCode = code;
      storage.set("userCode", code);
    },
  },
  actions: {},
  modules: {},
});
