<template>
  <div class="v-return">
    <van-nav-bar
      :title="title"
      :left-text="leftText"
      :right-text="rightText"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { NavBar } from "vant";
import { navigatorTo } from "@/utils/utils";
Vue.use(NavBar);
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    leftText: {
      type: String,
      default: "",
    },
    rightText: {
      type: String,
      default: "",
    },
  },
  watch: {},
  data() {
    return {};
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
      this.$emit("onClickLeft");
    },
    onClickRight() {
      this.$emit("onClickRight");
    },
  },
};
</script>

<style lang="less">
.v-return {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
}
</style>
